// Variables
////////////////////////////////

// .m-section
$m-section-margin: 0 0 40px;

// Mobile navigation
$mobile-navigation-header-bg-color: #171717;
$m-nav-toggle-font-size: 19px;
$m-nav-toggle-padding: 7px;

// Forms
$form-centered-max-width: 500px;
$form-control-font-size-base: 16px;

// Shared dependencies
@import "base/shared-themes-dependencies";

// Custom styles
@import "base/shared-essentials";
@import "base/shared-themes";
@import "components/forms";
@import "components/language_select";
@import "components/buttons";
@import "modules/m-section";
@import "modules/m-page-subheader";
@import "modules/m-checkout";

body {
  &.l-navbar-affixed {
    padding-top: 50px;
  }
}

img {
  max-width: 100%;
}

.modal {
  color: #333;
}

.modal.fade {
  display: none;
}

.splide {
  height: 100%;
}

.main-section {
  position: relative;
  min-height: 450px;

  &.section {
    padding-top: 50px;
  }

  p {
    margin-bottom: 10px;
  }

  .logo {
    padding: 2px;
    background: white;
    max-height: 160px;
  }

  &.carousel {
    padding-bottom: 60px; // Make sure there is enough space for carousel indicators.

    .m-indicators {
      margin-bottom: -60px;
    }
  }

  .m-indicators {
    margin-bottom: 0;
  }
}

.social {
  float: right;
  padding: 5px;
  margin-right: 5px;
  font-size: 17px;

  a {
    color: rgb(95, 95, 95);

    &:hover {
      text-decoration: none;
      opacity: $hover-opacity;
    }
  }
}

.m-indicators {
  position: absolute;
  left: 50%;
  margin-left: -30%;
  padding: 10px;
  bottom: 15px;
  top: auto;
  width: 60%;
  display: flex;
  align-items: center;

  li {
    line-height: 0;
  }

  &__btn {
    width: 10px;
    height: 10px;
    margin: 1px 5px;
    padding: 0;
    display: inline-block;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.55);
    border: 1px solid #fff;

    &:first-of-type {
      margin-left: 0;
    }

    &.is-active {
      width: 12px;
      height: 12px;
      background-color: #fff;
    }
  }
}

.restaurant-controls {
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 24px 5px;
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
  }

  a {
    margin-right: 5px;
    color: gray;
  }
  span {
    color: gray;
  }
}

.visible-affixed {
  display: none;

  @at-root .is-affixed .visible-affixed {
    display: inline-block; /* fallback for old browsers, including IE11 */
    display: initial;
  }
}

.widget {
  text-align: center;
  padding: 25px 15px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  strong {
    font-size: 40px;
    margin-left: 12px;
  }

  color: #333333;

  i {
    color: rgb(160, 160, 160);
  }
}
.gallery {
  &__placeholder {
    background-color: lightgray;
    width: 100%;
    height: 250px;
  }

  img {
    max-width: 100%;
  }

  .second-row {
    margin-top: 50px;
    text-align: center;

    .frame {
      overflow: hidden;
      height: 250px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.section {
  padding: 50px 0;
  background-size: 130%;
  background-attachment: fixed;
  position: relative;

  .m-indicators {
    margin-bottom: -50px;
  }

  &.with-background {
    .section-header {
      padding: 15px;
      color: #333;
      display: inline-block;
      background: white;
    }

    p {
      color: #333;
      padding: 5px;
      background: white;
      display: inline-block;
      max-width: 75%;
    }

    .button {
      box-shadow: 0 1px 1px rgba(#000, 0.2);
    }

    .section-subheader {
      padding: 5px 15px;
      background: white;
      color: #525252;
      margin-top: 0;
      font-weight: normal;
      display: inline-block;
    }

    p:empty {
      background: transparent;
      box-shadow: none;
    }

    @media screen and (max-width: $grid-float-breakpoint-max) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  p {
    margin-bottom: 20px;
  }
}

.checkout .section {
  padding: 20px 0;
}

img.collage {
  margin-right: 2px;
  margin-bottom: 2px;
  width: 250px;
  height: 168px;
  display: block;
  float: left;
}

.carousel {
  .item {
    width: 100%;
  }
}

.carousel.section {
  margin-bottom: 0;
}

#header {
  padding-left: 20px;
  padding-right: 20px;

  .header-info {
    margin-top: 5px;
    float: left;
  }
}

.navigation {
  text-transform: uppercase;
  height: 50px;

  .navigation__site-name {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      max-width: calc(100% - 50px);
      padding-left: 0;
    }
  }

  &.checkout {
    margin-bottom: 0;
  }

  .icon-reorder {
    &:before {
      margin-right: 0;
    }
  }
}

.navigation > ul {
  margin: 0;
}

.navigation > ul > li {
  padding: 0;
  margin: 0;
  list-style: none;
  float: left;
  line-height: 50px;
}

.navigation {
  .m-group-nav--dropdown {
    margin-top: 10px;
  }

  .m-group-nav__list--dropdown {
    left: auto;
    right: 0;
  }

  .m-group-nav__list--dropdown .m-group-nav__list-item {
    line-height: 1.2;
  }

  .m-group-nav__link--dropdown-toggle {
    padding: 10px 16px;
    line-height: 12px;
  }

  .m-nav-toggle__btn {
    line-height: 12px;
    margin-left: 10px;

    .icon-reorder:before {
      margin-left: 0;
    }
  }
}

.m-group-nav--tabs .m-group-nav__link {
  font-size: inherit;
}

.site-logo {
  max-height: 45px;
}

.navigation > ul > li > a {
  display: inline-block;
  padding: 0px 16px 0px;
  line-height: inherit;

  &.site-name {
    padding-left: 0;
  }

  .caret {
    vertical-align: middle;
    margin-top: 0;
  }
  &.food-tabs-toggle {
    line-height: 32px;
  }
}

.navigation > ul > li > a.cart-empty-message {
  display: none;
}

.navigation a.site-name {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;

  &.active {
    text-decoration: none;
  }
}

.site-name {
  &__header {
    margin: 0;
    font-size: inherit;
    text-transform: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.site-nav {
  float: right;

  @media screen and (max-width: $grid-float-breakpoint-max) {
    display: none;
  }
}

.navigation.is-affixed {
  background: white;
  position: fixed;
  top: 0;
  right: 0px;
  left: 0;
  margin: 0;
  z-index: $zindex-navbar;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.5);
  padding: 0 floor($grid-gutter-width / 2);
  transition: background-color 0.3s ease-in-out;

  .site-nav {
    float: left;
  }
  a.active {
    color: #b4b8bc;
  }
  & > ul > li > a {
    color: #041e1d;

    .caret {
      border-top-color: #041e1d;
    }

    &.food-tabs-toggle {
      background: #e7e7e7;
      border: 1px solid #c3c3c3;
    }
    .item-count {
      background: black;
      color: white;
    }
  }
  a.site-name {
    display: none;
  }

  .language-select {
    display: none;
  }
}

.navigation > ul > li > a.cart-empty-message {
  color: silver;
  font-style: italic;
  text-transform: none;
}

#footer.section {
  color: #fff;
  padding-bottom: 0;

  a.button {
    color: #171717;
    background: white;
  }

  .social {
    margin-left: 5px;
  }
}

.footer-copy {
  margin-top: 40px;
  padding: 20px;
}

.section-link {
  color: #171717;
  border-bottom: 1px solid #171717;
  text-transform: uppercase;
  text-decoration: none;
  padding: 3px;
  transition: border-bottom-color 0.2s linear;
}

.section-link:hover {
  border-bottom-color: #d10;
  text-decoration: none;
}

.section .button {
  color: white;
  border: none;

  &:hover {
    border: none;
  }
}

#footer.section a {
  color: #999999;
  border-bottom: none;
  word-wrap: break-word;
}

.inline-block {
  display: inline-block;
}

/* menu */

.btn,
.btn-group {
  border-radius: 0;
}

.dish-type-image > img {
  width: 100%;
}
.dish-type-image h3 {
  color: #333;
}

.dish-type-image.no-image {
  h3 {
    background: #181818;
    color: white;
  }
}

.menu-group-header {
  text-align: center;
}

.menu-items {
  list-style: none;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.menu-item {
  position: relative;
  width: 31%;
  margin-bottom: 30px;

  .dropdown-menu {
    left: auto;
    right: 0;
  }

  .table {
    td {
      padding: 2px;
      font-size: 12px;
      border-top: 1px solid #f2f2f2;
    }
  }

  .item-description {
    font-size: 12px;
  }

  .dish-image {
    margin-top: 0;
    display: inline-block;
  }

  .dish-image > a {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #333333;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    border: 1px solid #cccccc;
    border-bottom-color: #b3b3b3;
    background: white;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  h4 {
    font-size: 16px;
    display: inline-block;
    max-width: 67%;
    margin-top: 0;

    .label {
      position: absolute;
      top: -20px;
      left: 0;
      border-radius: 0;
    }
  }

  .price {
    padding-right: 10px;
  }

  & > .buttons {
    position: absolute;
    right: 10px;
    top: -15px;
    background: none;
    border-radius: 0;
  }

  .btn-group {
    border-radius: 0;
    background: white;

    .btn {
      border-radius: 0;
    }
  }
}

.no-pie-choice .dropdown-menu,
.no-pie-choice .caret {
  display: none;
}

/*************  Promocje na menu ****************/

.menu-promotions {
  z-index: 1;
  position: relative;
  padding: 15px 0;
  margin-bottom: 40px;
  background: #181818;

  .promotion-content > h2,
  .promotion-content > p {
    color: #fff;
  }

  .popover {
    max-width: 330px;
  }

  .close {
    opacity: 1;
    color: white;
    text-shadow: none;
  }

  .promotion-image {
    display: block;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      width: 70px;
      flex: 0 0 auto;
      margin-right: 15px;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: 20px;
    }
  }

  .promotion-content {
    @media screen and (max-width: $grid-float-breakpoint-max) {
      flex-grow: 1;
    }
  }

  h2 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__text {
    text-align: center;
    color: #fff;
  }

  &__show {
    display: block;
    font-size: 18px;
    color: #fff;

    &:hover,
    &:focus {
      color: inherit;
    }
  }

  // .closed {
  //   display: block;
  //   text-transform: uppercase;
  //
  //   a {
  //     text-decoration: none;
  //     margin-left: 5px;
  //     color: white;
  //     border-bottom: 1px solid rgb(184, 184, 184);
  //   }
  // }

  h2 {
    text-transform: uppercase;
    font-size: 30px;
  }

  .promotions-wrapper {
    margin-left: floor($grid-gutter-width / -2);
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .promotion {
    margin-bottom: floor($grid-gutter-height / 2);
    padding-left: floor($grid-gutter-width / 2);
    text-align: center;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      display: flex;
      flex-grow: 1;
      flex-basis: 280px;
      text-align: left;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      flex-basis: (100% / 3);
      max-width: (100% / 3);
      margin-bottom: $grid-gutter-height;
    }

    h2 {
      margin-top: 0;
    }
  }

  .promotion h2 {
    font-size: 18px;
  }

  .add-to-cart-info {
    background: green;
    padding: 10px;
    color: white;
    border-radius: 5px;
    text-align: center;
  }
}

#pizzas {
  .dish-type-image {
    margin-bottom: 0;
  }
}

.pizza-options {
  color: #333;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  background: #f3f3f3;
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 50px;

  & > div {
    flex-grow: 1;
  }
  .muted {
    color: #999;
  }

  & > .pizza-sizes {
    flex-grow: 3;
    text-align: center;
    line-height: 25px;

    ul {
      display: inline;
      list-style: none;
      > li {
        display: inline;
      }
    }
  }
  & > .creator {
    text-align: right;
  }
}

#dishes {
  margin-top: 50px;
  & > div {
    margin-bottom: 50px;
  }

  .dish-type-image {
    margin-bottom: 70px;
  }
}

#drinks {
  .size-info,
  .add-button {
    text-align: center;
  }
}

.dropdown-toggle {
  position: relative;

  .item-count {
    background: rgb(20, 15, 15);
    color: white;
    font-size: 9px;
    line-height: 14px;
    padding: 2px 7px;
    position: absolute;
    border-radius: 500px;
    top: 11px;
    right: -5px;
  }
}

.dish-type-image {
  height: 150px;
  margin-bottom: 15px;
  text-align: center;
  background-size: cover;
  position: relative;

  h3 {
    background: white;
    padding: 15px 30px;
    display: inline-block;
    margin-top: 41px;
    font-size: 48px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }

  .btn {
    position: absolute;
    right: 2px;
    bottom: 2px;
  }
}

hr.large {
  margin: 30px 0;
}

.pizza-sizes {
  margin-top: 5px;
}

/* BUTTONS */

.btn {
  background: white;
}

.button,
.btn-primary,
.btn-success {
  font-size: 15px;
  padding: 10px 20px;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.btn-default {
  padding: 10px 20px;
}

.btn.btn-success {
  background: #468847;
}

.button,
.btn.btn-primary {
  background: #140f0f;
  color: white;
  text-shadow: none;
  i {
    margin-left: 5px;
    font-size: 12px;
  }
}

.button {
  color: white;
  display: inline-block;
}

.btn-group > .btn:first-child {
  border-radius: 0;
}

.cart-list {
  @include clearfix;
  padding-left: 0;
}
.cart .header a.close-delivery,
.cart .header a.close-cart {
  margin-top: 0;
}
.navigation > ul > li > .cart-button,
.navigation > ul > li > a#checkout_shortcut {
  @extend .button;
  padding: 10px 10px;
  padding-right: 0;
  color: white;
  margin-left: 5px;
  line-height: 12px;
  font-size: 12px;
  cursor: pointer;

  i {
    margin-left: 0;
  }

  @media screen and (max-width: $grid-float-breakpoint-max) {
    margin-left: 10px;
  }
}

.navigation > ul > li > .cart-button {
  padding-right: 25px;

  .item-counter {
    top: 8px;
    right: 5px;
  }
}

.label-warning,
.badge-warning {
  color: #f89406;
  background-color: white;
  border: 1px solid #f89406;
  margin-left: 5px;
  border-radius: 0;
  font-weight: normal;
  text-shadow: none;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a.is-active,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background: #181818;
  color: white;
}

.btn-counter {
  position: relative;
}

.item-counter {
  position: absolute;
  top: 6px;
  right: 0px;
  line-height: 15px;
  font-size: 10px;
  padding: 0px 4px;
  border-radius: 100%;
  background: black;
  color: white;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
}

/* Reviews */

.review-image img {
  border-radius: 50px;
}

blockquote.review-comment {
  float: right;
}

.review-image {
  float: left;
  width: 100px;
}

.review-calendar {
  i {
    display: none;
  }
  margin-top: 2px;
}

.review-rating {
  text-align: left;
  font-size: 20px;
  margin-left: 100px;

  hr {
    border: none;
  }
}

.review-content > p:first-of-type {
  font-size: 18px;
  font-style: italic;
  text-align: center;
  background: rgb(250, 250, 250);
  padding: 20px;
  line-height: 36px;
  color: gray;
  margin-left: 45px;
}

.review-content > p.author {
  text-align: right;
  color: #d10;
  font-weight: bold;
  font-style: italic;
}

/* promocje */

.site-promotions {
  @media screen and (min-width: $grid-float-breakpoint) {
    margin-top: 50px;
  }
}

.site-promotions {
  .promotions {
    padding: 20px;
    margin-bottom: 20px;
  }

  .promotion-rules {
    text-align: center;
  }

  .promotions > .promotion {
    margin-bottom: 50px;
    position: relative;
  }

  .promotions > .promotion:last-child {
    margin-bottom: 0;
  }

  .promotions > .promotion h2 {
    font-size: 17px;
    margin: 0;
  }

  .promotion > .promotion-image {
    float: left;
    width: 193px;
    height: 193px;
  }

  .promotions .promotion-content {
    margin-left: 200px;
    height: 153px;
    padding: 20px;
  }

  .promotion .promotion-content .table {
    margin-bottom: 0;
    max-width: 75%;
    float: left;
  }

  .promotion-availability {
    .icon-ok {
      font-size: 18px;
    }
    .icon-remove {
      font-size: 10px;
    }
  }

  .promotions .promotion-content .button {
    float: right;
    margin-top: 35px;
    margin-bottom: 20px;
  }
}

.form-actions input[type="submit"] {
  float: right;
  padding: 15px 80px 15px 80px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  background: #006dcc;
}

/*  platnosci */

.summary {
  .panel.summary {
    text-align: center;

    .timer {
      display: inline-block;
    }
    .timer.hide {
      display: none;
    }
    .alert {
      padding: 50px;
      margin-bottom: 0;
    }
  }
}

/* strony wlasne */
.content-header {
  text-align: center;
}

.content-header .h2,
.content-header .h1 {
  display: inline-block;
  padding: 10px;
}
.content {
  margin-bottom: 50px;
}
.cart .content {
  margin-bottom: 0;
}

/* check delivery */

.cart {
  color: #333;

  .btn-default {
    color: #333;
  }
  a {
    color: #0088cc;
  }
}

// Basic hotfix for Simple dark theme color issues (e.g. white link on white background) without increasing declaration specificity with `.dark` parent.
.modal,
.popover,
.card,
.modal,
.checkout section {
  a {
    color: #0088cc; // the same color as color hardcoded in other places (e.g. link inside cart, btn-link and maybe other defaults from Bootstrap)
  }
}

.card,
form.checkout section {
  color: #333;

  .button.raised {
    background: #000;
    color: #fff;
  }
}

.popover {
  color: #333;
}
.alert {
  border-radius: 0;
}
.alert-danger,
.alert-error {
  background: #d10;
  color: white;
  text-shadow: none;
  border: none;

  ul {
    margin-bottom: 0;

    li:before {
      font-family: FontAwesome;
      content: "\e813";
      text-decoration: inherit;
      display: inline-block;
      speak: none;
      margin-right: 5px;
    }
  }

  .btn-danger {
    color: #d10;

    &:hover,
    &:focus {
      color: white;
    }
  }
}

.phone-cart-item .phone-item-description {
  font-size: 10px;
  line-height: 12px;
}

@media (max-width: 1199px) {
  .section {
    &.main-section {
      padding-top: 20px;
      background-size: cover;
    }
  }
}

@media (max-width: 768px) {
  .menu-items {
    flex-direction: column;
    padding: 0;
  }

  .section {
    background-attachment: scroll;

    .h1 {
      font-size: 20px;
    }
    .h3 {
      font-size: 15px;
    }
  }
  .slider-content {
    padding: 10px;
  }
  .offset1 {
    margin-left: 0;
  }
  .carousel .promotion-box {
    padding: 10px;
  }
  .our-menu {
    .col-md-6 {
      text-align: center;
    }
    img.collage {
      display: none;
    }
    img.collage:first-of-type {
      display: inline-block;
      width: auto;
      float: none;
    }
  }
  #header {
    padding-left: 0;
    padding-right: 0;
  }
  .header-info {
    display: none;
  }

  .navigation.is-affixed .site-nav {
    display: none;
  }

  .navigation.is-affixed a.site-name {
    display: block;
  }
  .navigation.is-affixed.menu a.site-name {
    display: none;
  }
  .cart-dropdown .dropdown-menu {
    width: 310px;
  }
  .menu-promotions.open small .close {
    float: none;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
  }
  .menu-item {
    width: 100%;

    .product-name {
      width: 60%;
    }
  }
  .pizza-options,
  .pizza-options > .creator {
    text-align: center;
  }
  .footer-details {
    text-align: center;
  }
  .slider-panel .open-button {
    display: none;
  }
  .checkout h3 {
    font-size: 20px;
  }
  .checkout .header-section {
    display: none;
  }
  .checkout + #footer {
    display: none;
  }
  form#checkout {
    .form-actions {
      .aggreement {
        float: none;
        margin: 0;
      }
      input {
        float: none;
        width: 100%;
        margin-top: 20px;
      }
    }
    .input-prepend {
      width: 100%;
      input {
        width: 91%;
      }
    }
    .control-group.error .help-block:after {
      border: 5px solid transparent;
      border-bottom-color: #d50000;
      left: 3px;
      top: -10px;
    }
  }
}

@media (max-width: 479px) {
  .dish-type-image {
    .menu-group-header {
      padding-right: 15px;
      padding-left: 15px;
      font-size: 38px;
    }
  }

  .site-promotions {
    .promotion {
      & > .promotion-image {
        float: none;
        width: auto;
        height: auto;
      }

      .promotion-content {
        height: auto;
        margin-left: 0;
      }
    }
  }
}

.payment-waiting-alert {
  text-align: center;
}
